import Vue from 'vue'
import Vuex from 'vuex'
import {getTimeInterval} from '../utils/index'
import {fetchSocial,fetchSiteInfo} from '@/api'

import modules from '../state/modules'
import axios from "axios";

const blogApi = axios.create({ // 博客后台api地址
    baseURL: process.env.VUE_APP_REQUEST_BASEURL
})
Vue.use(Vuex)
// 略:后台获取系统运行时间
const runAt = '1631081544382';
let timer = null;
const state = {
    loading: false,
    runTimeInterval: '',
    socials: '',
    websiteInfo: ''
}
const mutations = {
    SET_LOADING: (state, v) => {
        state.loading = v;
    },
    SET_SOCIALS: (state, v) => {
        state.socials = v;
    },
    SET_SITE_INFO: (state, v) =>{
      state.websiteInfo = v;
    },
    GET_RUNTIME_INTERVAL: (state) => {
        if (!timer || !state.runTimeInterval) {
            clearInterval(timer)
            timer = setInterval(() => {
                state.runTimeInterval = getTimeInterval(runAt);
            }, 1000);
        }
    }
}
const actions = {
    setLoading: ({commit}, v) => {
        commit('SET_LOADING', v);
    },
    initComputeTime: ({commit}) => {
        commit('GET_RUNTIME_INTERVAL');
    },
    getSiteInfo: ({commit,state}) =>{
        return new Promise(resolve => {
            if (state.websiteInfo){
                resolve(state.websiteInfo)
            }else {
                const {data: res} =  blogApi.get('/home/getWebSiteInfo').then(res=>{
                    let data = res.data.data.site || {}
                    commit('SET_SITE_INFO',data);
                    resolve(data);
                }).catch(err => {
                    console.log(err)
                    resolve({});
                })
            }
        })
    },
    getSocials: ({commit,state}) =>{
        return new Promise((resolve => {
            if (state.socials){
                resolve(state.socials)
            } else {
                const {data: res} =  blogApi.get('/home/getWebSiteInfo').then(res=>{
                    let data = res.data.data.social || []
                    commit('SET_SOCIALS',data);
                    resolve(data);
                }).catch(err =>{
                    console.log(err)
                    resolve([]);
                })
            }
        }))
    }
}
const getters = {
    loading: state => state.loading,
    runTimeInterval: state => state.runTimeInterval,
    notice: state => state.websiteInfo?state.websiteInfo.notice:''
}
export default new Vuex.Store({
    state,
    mutations,
    actions,
    modules,
    // Enable strict mode in development to get a warning
    // when mutating state outside of a mutation.
    // https://vuex.vuejs.org/guide/strict.html
    // strict: process.env.NODE_ENV !== 'production',
    getters
})
