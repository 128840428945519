
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Vuelidate from 'vuelidate'
import VueSweetalert2 from 'vue-sweetalert2';
import VueMask from 'v-mask'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueYoutube from 'vue-youtube'

import vco from "v-click-outside"

import router from './router'
import store from '@/store'
import i18n from './i18n'

import "@/assets/scss/app.scss";

import { initFirebaseBackend } from './helpers/firebase/authUtils';

import { configureFakeBackend } from './helpers/fakebackend/fake-backend';

import marked from 'marked'
import axios from 'axios'
import {VueJsonp} from 'vue-jsonp'
import 'nprogress/nprogress.css'
import md5 from 'js-md5'
import Vue from 'vue';
const firebaseConfig = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
  projectId: process.env.VUE_APP_PROJECTId,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPId,
  measurementId: process.env.VUE_APP_MEASUREMENTID
};

if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
  initFirebaseBackend(firebaseConfig);
} else {
  configureFakeBackend();
}

Vue.config.productionTip = false
Vue.use(VueYoutube)
Vue.use(BootstrapVue)
Vue.use(vco)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2);
Vue.use(VueMask)
Vue.use(require('vue-chartist'))

Vue.prototype.$md5 = md5;
Vue.prototype.$marked = marked
Vue.prototype.$http = axios
Vue.prototype.axios = axios;
Vue.prototype.$jsonp = VueJsonp
Vue.use(VueJsonp)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE',
    libraries: 'places',
  },
  installComponents: true
})
Vue.component('apexchart', VueApexCharts)
const blog = axios.create({ // 博客后台api地址
                            // baseURL: 'http://localhost:8099/myblog'
  baseURL: process.env.VUE_APP_REQUEST_BASEURL
})

// const picture = axios.create({ // 图片服务器api地址
//   baseURL: 'https://82.156.112.62/uploadfile/'
// })

blog.interceptors.request.use(config => {
  let token = sessionStorage.getItem('token');
  if (token != null && token != undefined) {
    config.headers.token = window.sessionStorage.getItem('token')
  }
  return config
})

blog.interceptors.response.use(
    response  => {
      if (response.data.code == "50000") {
        // 这里写清除token的代码
        sessionStorage.removeItem("user");
        sessionStorage.removeItem("token");
        // 返回登录页面
        window.location.href='#/login'
        return;
      }
      return response
    },error => {
      if (error.response) {
        switch (error.response.status) {
          case 401:
            // 这里写清除token的代码
            sessionStorage.removeItem("user");
            sessionStorage.removeItem("token");
            // 返回登录页面
            window.location.href='#/login'
            // router.replace({
            //   path: 'login',
            //   query: {redirect: router.currentRoute.fullPath}  //登录成功后跳入浏览的当前页面
            // })
        }
      }
    }
)
Vue.prototype.$blog = blog
// Vue.prototype.picServer = 'https://81.70.197.83/uploadfile/';
Vue.prototype.picServer = '/uploadfile/';

Vue.filter('dataFormat', function (originVal) {
  const dt = new Date(originVal)
  const y = dt.getFullYear()
  const m = (dt.getMonth() + 1 + '').padStart(2, '0')
  const d = (dt.getDate() + '').padStart(2, '0')
  return `${y}-${m}-${d}`
})

Vue.filter('dataFormat2', function (originVal) {
  const dt = new Date(originVal)
  const y = dt.getFullYear()
  const m = (dt.getMonth() + 1 + '').padStart(2, '0')
  return `${y}-${m}`
})

Vue.filter('dataFormat3', function (originVal) {
  const dt = new Date(originVal)
  let usedTime = new Date() - dt
  let days = Math.floor(usedTime / (24 * 3600 * 1000)); // 计算出天数
  let leavel = usedTime % (24 * 3600 * 1000); // 计算天数后剩余的时间
  let hours = Math.floor(leavel / (3600 * 1000)); // 计算剩余的小时数
  let leavel2 = leavel % (3600 * 1000); // 计算剩余小时后剩余的毫秒数
  let minutes = Math.floor(leavel2 / (60 * 1000)); // 计算剩余的分钟数
  if (days > 0) return days + '天前'
  if (hours > 0) return hours + '小时前'
  if (minutes <= 0) {
    minutes  = 1;
  }
  return minutes + '分前'
})



Vue.filter('dataFormat4', function (originVal) {
  const dt = new Date(originVal)
  const y = dt.getFullYear()
  const m = (dt.getMonth() + 1 + '').padStart(2, '0')
  const d = (dt.getDate() + '').padStart(2, '0')
  const h = (dt.getHours()+ '').padStart(2, '0')
  const M = (dt.getMinutes()+ '').padStart(2, '0')
  const s = (dt.getSeconds()+ '').padStart(2, '0')
  return `${y}-${m}-${d} ${h}:${M}:${s}`
})

import './assets/css/style.less'
import './assets/font/iconfont.css'
// require('./Mock')
import {parseTime} from './utils'

Vue.config.productionTip = false
Vue.filter('parseTime', (v) => parseTime(v,'{y}-{m}-{d}'))
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

